<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ inspectionForm.name }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("COMMON.TAGS") }}
        </dt>
        <dd class="col-sm-8">
          <tags
            :tags="inspectionForm.tags"
            @tagsUpdated="inspectionFormUpdated"
          />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ inspectionForm.name }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("FUEL_TYPES.FUEL_TYPES") }}</dt>
        <dd class="col-sm-8">
          <fuel-types :fuel-types="inspectionForm.fuel_types" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8" v-html="inspectionForm.excerpt"></dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("INSPECTION_FORMS.MAX_POINTS") }}</dt>
        <dd class="col-sm-8">
          {{ inspectionForm.maxPoints }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("INSPECTION_FORMS.SECTIONS_COUNT") }}</dt>
        <dd class="col-sm-8">
          {{ inspectionForm.inspectionFormSections.length }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("INSPECTION_FORMS.FIELDS_COUNT") }}</dt>
        <dd class="col-sm-8">
          {{ inspectionForm.fieldsCount }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="inspectionForm.allowedLocations" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="inspectionForm.organization" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="inspectionForm.created_at">
          {{ $timeZoneDateFormat(inspectionForm.created_at, "LLLL") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="inspectionForm.updated_at">
          {{ $timeZoneDateFormat(inspectionForm.updated_at, "LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";
import Organization from "@/components/Organization.vue";
import FuelTypes from "@/components/FuelTypes.vue";

export default {
  name: "inspection-form-view-global",

  components: {
    Tags,
    Organization,
    FuelTypes,
  },

  props: ["inspectionForm"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    inspectionFormUpdated() {
      this.$emit("inspectionFormUpdated", true);
    },
  },

  mounted() {},

  watch: {
    inspectionForm(inspectionForm) {},
  },
};
</script>
