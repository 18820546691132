export default {
  type: "inspection-forms",
  name: null,
  fuel_types: [],
  excerpt: "",
  relationshipNames: [
    "organization",
    "inspectionFormSections",
    "inspectionFormSectionFields",
    "tags",
    "allowedLocations",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  inspectionFormSections: [],
  inspectionFormSectionFields: [],
  tags: [],
  allowedLocations: [],
};
