var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"disabled":!!_vm.inspectionForm.id,"organization":_vm.inspectionForm.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
            _vm.inspectionForm.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.inspectionForm.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.inspectionForm.organization.id},on:{"locationsChanged":(locations) => {
            _vm.inspectionForm.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.inspectionForm.name),callback:function ($$v) {_vm.$set(_vm.inspectionForm, "name", $$v)},expression:"inspectionForm.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.company_name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"type":"textarea","label":`${_vm.$t('INSPECTION_FORMS.FUEL_TYPES')}`,"placeholder":_vm.$t('INSPECTION_FORMS.FUEL_TYPES')}},[_c('fuel-types-selector',{attrs:{"value":_vm.inspectionForm.fuel_types},on:{"fuelTypesChanged":(fuelTypes) => (_vm.inspectionForm.fuel_types = fuelTypes)}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.fuel_types}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"type":"textarea","label":`${_vm.$t('COMMON.EXCERPT')} (*)`,"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.inspectionForm.excerpt),callback:function ($$v) {_vm.$set(_vm.inspectionForm, "excerpt", $$v)},expression:"inspectionForm.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAGS')}`,"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"tags":_vm.inspectionForm.tags,"disabled":!_vm.inspectionForm.organization.id,"organization":_vm.inspectionForm.organization.id},on:{"tagsChanged":(tags) => {
            _vm.inspectionForm.tags = tags;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.inspectionForm.id ? _vm.$t("INSPECTION_FORMS.EDIT_INSPECTION_FORM") : _vm.$t("INSPECTION_FORMS.ADD_INSPECTION_FORM"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }