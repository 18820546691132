<template>
  <div>
    <div class="tab-pane-inner">
      <div class="add-section">
        <base-button
          class="add"
          icon
          size="sm"
          @click="
            () => {
              openSubSectionModal();
            }
          "
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_SUB_SECTION") }}
          </span>
        </base-button>
        <div class="bend-buttons">
          <base-button icon size="sm" @click="expandAll">
            <span class="btn-inner--icon">
              <img src="/img/maximize-solid.svg" alt="icon" />
            </span>
            <span class="btn-inner--text"> {{ $t("COMMON.EXPAND_ALL") }} </span>
          </base-button>
          <base-button icon size="sm" @click="collapseAll">
            <span class="btn-inner--icon">
              <img src="/img/compress-arrows-alt-solid.svg" alt="icon" />
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.COLLAPSE_ALL") }}
            </span>
          </base-button>
        </div>
      </div>
      <div class="section-list">
        <collapse :activeIndexes="activeItems" :activeIndex="-1">
          <collapse-item
            v-for="(subSection, index) in section.subSections"
            v-bind:key="subSection.id"
          >
            <div slot="title" class="collapse-header">
              <div class="collapse-header-left">
                <div class="section-image">
                  <img
                    v-if="subSection.icon"
                    :src="`${subSection.icon}`"
                    class="argon-image mb-2"
                  />
                </div>
                <div class="section-name">
                  <span class="name">
                    {{ subSection.name }}
                  </span>
                  <span class="max-points">
                    ({{ subSection.maxPoints }}
                    {{ $t("INSPECTION_FORMS.MAX_POINTS") }})
                  </span>
                  <span class="factor">
                    ({{ $t("INSPECTION_FORMS.FACTOR_ABBREV") }} :
                    {{ subSection.factor }} /
                    {{ section.totalSubSectionFactor }})
                  </span>
                </div>
                <!--<div class="section-factor"></div>-->
              </div>
              <div class="collapse-header-right">
                <base-button size="sm" v-if="loading">
                  <i class="fal fa-spinner fa-spin"></i>
                </base-button>
                <base-button
                  size="sm"
                  @click.stop="reorderSectionDown(subSection)"
                  :disabled="loading"
                  v-if="index < section.subSections.length - 1 && !loading"
                >
                  <i class="fal fa-long-arrow-down"></i>
                </base-button>
                <base-button
                  size="sm"
                  @click.stop="reorderSectionUp(subSection)"
                  :disabled="loading"
                  v-if="index > 0 && !loading"
                >
                  <i class="fal fa-long-arrow-up"></i>
                </base-button>
                <base-button
                  size="sm"
                  @click.stop="openSubSectionModal(subSection)"
                  :disabled="loading"
                >
                  <i class="fal fa-edit"></i>
                </base-button>
                <base-button
                  size="sm"
                  @click.stop="deleteSubSection(subSection)"
                  :disabled="loading"
                >
                  <i class="fal fa-trash-alt"></i>
                </base-button>
                <span class="chevron">
                  <i class="fal fa-chevron-down"></i>
                </span>
              </div>
            </div>
            <div>
              <div class="description" v-html="subSection.excerpt"></div>
              <div class="fields">
                <inspection-form-section-field-list
                  :inspectionFormSection="subSection"
                  :inspectionForm="inspectionForm"
                  @onChangeInspectionFormField="inspectionFormFieldChanged"
                />
              </div>
            </div>
          </collapse-item>
        </collapse>
      </div>
    </div>

    <!-- Modal add slot -->
    <inspection-form-section-form
      :inspectionForm="section.inspectionForm"
      :inspectionFormSection="subSection"
      :showModal="showModal"
      :loading="loading"
      :formErrors="formErrors"
      @onCloseInspectionFormSectionModal="closeSubSectionModal"
      @onAddInspectionFormSectionForm="addSubSection"
      @onEditInspectionFormSectionForm="editSubSection"
      @onDeleteInspectionFormSection="deleteSubSection"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import requestErrorMixin from "@/mixins/request-error-mixin";
import InspectionFormSectionForm from "./InspectionFormSectionForm.vue";
import defaultInspectionFormSection from "./defaultInspectionFormSection";
import InspectionFormSectionFieldList from "../InspectionFormSectionField/InspectionFormSectionFieldList.vue";

export default {
  name: "inspection-form-section-list-sub-section-list",

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    InspectionFormSectionForm,
    InspectionFormSectionFieldList,
    Collapse,
    CollapseItem,
  },

  mixins: [requestErrorMixin],

  props: ["section", "inspectionForm"],

  data() {
    return {
      activeItems: [],
      showModal: false,
      subSection: cloneDeep(defaultInspectionFormSection),
      loading: false,
      formErrors: null,
    };
  },

  computed: {},

  methods: {
    expandAll() {
      this.activeItems = Array.from(
        { length: this.section.subSections.length },
        (_, index) => index
      );
    },

    collapseAll() {
      this.activeItems = [];
    },

    openSubSectionModal(subSection = null) {
      if (subSection) {
        this.subSection = subSection;
      } else {
        this.subSection = cloneDeep(defaultInspectionFormSection);
      }
      this.showModal = true;
    },

    closeSubSectionModal() {
      this.showModal = false;
    },

    async addSubSection(inspectionFormSection) {
      this.loading = true;
      try {
        const subSectionData = cloneDeep(inspectionFormSection);
        subSectionData.order_number = this.section.subSections.length + 1;
        subSectionData.inspectionForm.id = this.section.inspectionForm.id;
        subSectionData.parent = {
          type: "inspection-form-sections",
          id: this.section.id,
        };

        await this.$store.dispatch(
          "inspectionFormSections/add",
          subSectionData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_SECTION_ADDED"),
        });
        this.inspectionFormFieldChanged();
        this.closeSubSectionModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async editSubSection(inspectionFormSection) {
      this.loading = true;
      try {
        const subSectionData = cloneDeep(inspectionFormSection);
        await this.$store.dispatch(
          "inspectionFormSections/update",
          subSectionData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_SECTION_UPDATED"),
        });
        this.inspectionFormFieldChanged();
        this.closeSubSectionModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async reorderSectionUp(subSection) {
      try {
        this.loading = true;
        const subSectionData = cloneDeep(subSection);
        const index = this.section.subSections.findIndex(
          (el) => el.id === subSectionData.id
        );
        const prevSectionData = cloneDeep(this.section.subSections[index - 1]);
        subSectionData.order_number = index - 1;
        prevSectionData.order_number = index;
        await this.$store.dispatch(
          "inspectionFormSections/update",
          subSectionData
        );
        await this.$store.dispatch(
          "inspectionFormSections/update",
          prevSectionData
        );
        this.inspectionFormFieldChanged();
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async reorderSectionDown(subSection) {
      try {
        this.loading = true;
        const subSectionData = cloneDeep(subSection);
        const index = this.section.subSections.findIndex(
          (el) => el.id === subSectionData.id
        );
        const nextSectionData = cloneDeep(this.section.subSections[index + 1]);
        subSectionData.order_number = index + 1;
        nextSectionData.order_number = index;
        await this.$store.dispatch(
          "inspectionFormSections/update",
          subSectionData
        );
        await this.$store.dispatch(
          "inspectionFormSections/update",
          nextSectionData
        );
        this.inspectionFormFieldChanged();
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deleteSubSection(subSection) {
      this.loading = true;
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM_SECTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "inspectionFormSections/destroy",
            subSection.id
          );
          this.inspectionFormFieldChanged();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t(
              "INSPECTION_FORMS.INSPECTION_FORM_SECTION_DELETED"
            ),
          });
          this.loading = false;
          this.showModal = false;
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    inspectionFormFieldChanged() {
      this.$emit("onChangeInspectionForm", true);
    },
  },

  mounted() {},

  watch: {},
};
</script>
