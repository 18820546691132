<template>
  <div>
    <div class="tab-pane-inner">
      <div class="add-section">
        <base-button
          class="add"
          icon
          size="sm"
          @click="
            () => {
              openInspectionFormMultiplierModal();
            }
          "
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_MULTIPLIER") }}
          </span>
        </base-button>
        <div class="bend-buttons">
          <base-button icon size="sm" @click="expandAll">
            <span class="btn-inner--icon">
              <img src="/img/maximize-solid.svg" alt="icon" />
            </span>
            <span class="btn-inner--text"> {{ $t("COMMON.EXPAND_ALL") }} </span>
          </base-button>
          <base-button icon size="sm" @click="collapseAll">
            <span class="btn-inner--icon">
              <img src="/img/compress-arrows-alt-solid.svg" alt="icon" />
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.COLLAPSE_ALL") }}
            </span>
          </base-button>
        </div>
      </div>
      <div class="section-list">
        <collapse :activeIndexes="activeItems" :activeIndex="-1">
          <collapse-item
            v-for="multiplier in inspectionForm.inspectionFormMultipliers"
            v-bind:key="multiplier.id"
          >
            <div slot="title" class="collapse-header">
              <div class="collapse-header-left">
                <div class="section-name">
                  <span class="name">
                    {{
                      $t(
                        `INSPECTION_FORMS.MULTIPLIER_FIELD_${multiplier.field.toUpperCase()}`
                      )
                    }}
                    &nbsp; - &nbsp;
                    {{
                      $t(
                        `INSPECTION_FORMS.MULTIPLIER_OPERATION_${multiplier.operation.toUpperCase()}`
                      )
                    }}
                    &nbsp; : &nbsp;
                    {{ multiplier.value }}
                  </span>
                </div>
              </div>
              <div class="collapse-header-right">
                <base-button
                  size="sm"
                  @click.stop="openInspectionFormMultiplierModal(multiplier)"
                  :disabled="loading"
                >
                  <i class="fal fa-edit"></i>
                </base-button>
                <base-button
                  size="sm"
                  @click.stop="deleteInspectionFormMultiplier(multiplier)"
                  :disabled="loading"
                >
                  <i class="fal fa-trash-alt"></i>
                </base-button>
              </div>
            </div>
            <div class="details-multiplier">
              <div>
                {{ $t(`INSPECTION_FORMS.MULTIPLIER_TYPE`) }} :
                <strong>
                  {{
                    $t(
                      `INSPECTION_FORMS.MULTIPLIER_TYPE_${multiplier.multiplier_type.toUpperCase()}`
                    )
                  }}
                </strong>
              </div>
              <div>
                {{ $t(`INSPECTION_FORMS.MULTIPLIER_FIELD`) }} :
                <strong>
                  {{
                    $t(
                      `INSPECTION_FORMS.MULTIPLIER_FIELD_${multiplier.field.toUpperCase()}`
                    )
                  }}
                </strong>
              </div>
              <div>
                {{ $t(`INSPECTION_FORMS.MULTIPLIER_OPERATION`) }} :
                <strong>
                  {{
                    $t(
                      `INSPECTION_FORMS.MULTIPLIER_OPERATION_${multiplier.operation.toUpperCase()}`
                    )
                  }}
                </strong>
              </div>
              <div>
                {{ $t(`INSPECTION_FORMS.MULTIPLIER_VALUE`) }} :
                <strong>
                  {{ multiplier.value }}
                </strong>
              </div>
              <div>
                {{ $t(`INSPECTION_FORMS.MULTIPLIER_MULTIPLIER`) }} :
                <strong>
                  {{ multiplier.multiplier }}
                </strong>
              </div>
            </div>
          </collapse-item>
        </collapse>
      </div>
    </div>

    <!-- Modal add slot -->
    <inspection-form-multiplier-form
      :inspectionForm="inspectionForm"
      :inspectionFormMultiplier="inspectionFormMultiplier"
      :showModal="showModal"
      :loading="loading"
      :formErrors="formErrors"
      @onCloseInspectionFormMultiplierModal="closeInspectionFormMultiplierModal"
      @onAddInspectionFormMultiplierForm="addInspectionFormMultiplierForm"
      @onEditInspectionFormMultiplierForm="editInspectionFormMultiplierForm"
      @onDeleteInspectionFormMultiplier="deleteInspectionFormMultiplier"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import requestErrorMixin from "@/mixins/request-error-mixin";
import InspectionFormMultiplierForm from "./InspectionFormMultiplierForm.vue";
import defaultInspectionFormMultiplier from "./defaultInspectionFormMultiplier";

export default {
  name: "inspection-form-multiplier-list",

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    InspectionFormMultiplierForm,
    Collapse,
    CollapseItem,
  },

  mixins: [requestErrorMixin],

  props: ["inspectionForm"],

  data() {
    return {
      activeItems: [],
      inspectionFormMultiplier: cloneDeep(defaultInspectionFormMultiplier),
      showModal: false,
      loading: false,
      formErrors: null,
    };
  },

  computed: {},

  methods: {
    expandAll() {
      this.activeItems = Array.from(
        { length: this.inspectionForm.inspectionFormMultipliers.length },
        (_, index) => index
      );
    },

    collapseAll() {
      this.activeItems = [];
    },

    openInspectionFormMultiplierModal(inspectionFormMultiplier = null) {
      if (inspectionFormMultiplier) {
        this.inspectionFormMultiplier = inspectionFormMultiplier;
      } else {
        this.inspectionFormMultiplier = cloneDeep(
          defaultInspectionFormMultiplier
        );
      }
      this.showModal = true;
    },

    closeInspectionFormMultiplierModal() {
      this.showModal = false;
    },

    async addInspectionFormMultiplierForm(inspectionFormMultiplier) {
      this.loading = true;
      try {
        const inspectionFormMultiplierData = cloneDeep(
          inspectionFormMultiplier
        );
        inspectionFormMultiplierData.inspectionForm.id = this.inspectionForm.id;

        await this.$store.dispatch(
          "inspectionFormMultipliers/add",
          inspectionFormMultiplierData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_MULTIPLIER_ADDED"),
        });
        this.inspectionFormFieldChanged();
        this.closeInspectionFormMultiplierModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async editInspectionFormMultiplierForm(inspectionFormMultiplier) {
      this.loading = true;
      try {
        const inspectionFormMultiplierData = cloneDeep(
          inspectionFormMultiplier
        );

        await this.$store.dispatch(
          "inspectionFormMultipliers/update",
          inspectionFormMultiplierData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t(
            "INSPECTION_FORMS.INSPECTION_FORM_MULTIPLIER_UPDATED"
          ),
        });
        this.inspectionFormFieldChanged();
        this.closeInspectionFormMultiplierModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async deleteInspectionFormMultiplier(inspectionFormMultiplier) {
      this.loading = true;
      const confirmation = await swal.fire({
        title: this.$t(
          "INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM_MULTIPLIER"
        ),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "inspectionFormMultipliers/destroy",
            inspectionFormMultiplier.id
          );
          this.inspectionFormFieldChanged();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t(
              "INSPECTION_FORMS.INSPECTION_FORM_MULTIPLIER_DELETED"
            ),
          });
          this.loading = false;
          this.showModal = false;
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    inspectionFormFieldChanged() {
      this.$emit("onChangeInspectionForm", true);
    },
  },

  mounted() {},

  watch: {
    inspectionForm(inspectionForm) {},
  },
};
</script>
